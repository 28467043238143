export const ActionTypes = {
	User: {
		SET_USER: "SET_USER",
	},
	Particepent: {
		GET_PARTICEPENT: "GET_PARTICEPENT",
		DELETE_PARTICEPENT: "DELETE_PARTICEPENT",
	},
	PayForm: {
		ADD_DATA: "ADD_DATA",
		RESET_FORM: "RESET_FORM",
	},
	Account: {
		ALL_ACCOUNTS: "ALL_ACCOUNTS"
	},
	AddOrRecieveFund: {
		Check_Debit_Card: "Check_Debit_Card"
	}
};
