import React from "react";
import "./Loader.css";

const Loader = () => {
	return (
		<div className='loader_wrapper'>
			<div className='spinner-grow loader_bg' role='status'></div>
			<div>Just a moment...</div>
		</div>
	);
};

export default Loader;
