import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom"
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import "./Sidebar.css";
import { CSSTransition } from "react-transition-group";

const SubMenu = ({ item, handleClose }) => {
  const [subnav, setSubnav] = useState(false);
  const router = useLocation();
  const nodeRef = useRef(null);

  const handleClick = (item) => {
    if (item.showSubNav) {
      setSubnav(!subnav)
    } else {
      if (handleClose) handleClose()
    }
  }

  const path = router?.pathname || ''

  const isActive = path.includes(item.path) || !!item.routes?.includes(router?.pathname);

  return <>
    <Link style={{ textDecoration: "none" }} onClick={() => handleClick(item)} to={item.showSubNav ? null : item?.path}>
      <div className="sidebar-links-container">
        <div className="sidebar-links">
          <div className="d-flex align-items-center px-3 py-2 justify-content-between" style={{ width: "250px", }}>
            <div className="d-flex align-items-center ">
              <div className="sidebar-links-img" style={{ flex: 0.3 }}>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={!isActive ? item?.activeImg : item.inActiveImg}
                  alt="icon"
                />
              </div>
              <div
                id={item.id}
                className="sidebar-links-text d-flex align-items-center"
                style={{ flex: 2, paddingLeft: "10px" }}
              >
                <p
                  className={
                    isActive
                      ? "sidebar-links-text-p m-0"
                      : "sidebar-links-text-p-inactive m-0"
                  }
                >
                  {item?.text}
                </p>
                {item.showSubNav && (
                  <div className="ms-2 sidebar-links-icon">
                    {subnav ? <FaAngleUp color="rgba(233, 233, 234, 0.74)" /> : <FaAngleDown color="rgba(233, 233, 234, 0.74)" />}
                  </div>
                )}
              </div>
            </div>
            {(isActive) && <div className="active-link-btn" ></div>}
          </div>
        </div>
      </div>
    </Link>
    <CSSTransition
      in={subnav && item?.subNav?.length}
      nodeRef={nodeRef}
      timeout={300}
      classNames="new-box"
      unmountOnExit
    >
      <div className="mb-3 " ref={nodeRef}>{
        item?.subNav?.map((subNavItem, index) =>
          <Link onClick={() => handleClick(subNavItem)} style={{ textDecoration: "none" }} to={subNavItem?.path}>
            <div className="sidebar-links-container">
              <div className="sidebar-links">
                <div className="d-flex align-items-center px-3 py-1 justify-content-between" style={{ width: "250px", borderRadius: "32px", background: item.path === router?.pathname || router.pathname === item.route ? "#0B436D" : 'unset', }}>
                  <div className="d-flex align-items-center ">
                    <div className="sidebar-links-img" style={{ flex: 0.3 }}></div>
                    <p
                      className={
                        "sidebar-sub-links-text-p m-0"
                      }
                    >
                      {subNavItem?.text}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </CSSTransition>
  </>
}

export default SubMenu