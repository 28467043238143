import { ActionTypes } from "../actionTypes";

const initialState = null;

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.Account.ALL_ACCOUNTS:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
