import { combineReducers } from "redux";
import userReducer from "./userReducer";
import particepentReducer from "./requestPaymentReducer";
import payFormReducer from "./payFormReducer"
import userAccount from "./userAccount"
import addOrReciveFund from "./addOrRecieveFundReducer"

const reducers = combineReducers({
	userAccount: userAccount,
	user: userReducer,
	particepent: particepentReducer,
	payForm: payFormReducer,
	addFund: addOrReciveFund
});

export default reducers;
