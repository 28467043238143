import React, { Suspense, } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "./components/Loader";
import ProtectedRoute from "./components/ProtectedRoute";
import { useWindowScroll } from "./utils/hooks";
const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const Features = React.lazy(() => import("./pages/Features"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Pricing = React.lazy(() => import("./pages/Pricing"));
const Business = React.lazy(() => import("./pages/Business"));
const Developers = React.lazy(() => import("./pages/Developers"));
const DeveloperApi = React.lazy(() => import("./pages/DeveloperApi"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const Login = React.lazy(() => import("./pages/Login"));
const PasswordReset = React.lazy(() => import("./pages/PasswordReset"));
const UserPasswordReset = React.lazy(() => import("./pages/PasswordReset/UserPasswordReset"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Client = React.lazy(() => import("./pages/Client"));
const AddNewClient = React.lazy(() => import("./pages/Client/Create/AddNewClient"));
const UpdateClient = React.lazy(() => import("./pages/Client/Update/UpdateClient"));
const Inventory = React.lazy(() => import("./pages/Inventory"));
const AddNewInventory = React.lazy(() => import("./pages/Inventory/Create/AddNewCatalog"));
const UpdateInventory = React.lazy(() => import("./pages/Inventory/View/UpdateCatalog"));
const ItemAndService = React.lazy(() => import("./pages/ItemAndService"));
const Invoices = React.lazy(() => import("./pages/Invoices"));
const CreateInvoices = React.lazy(() => import("./pages/Invoices/Create"));
const ViewInvoice = React.lazy(() => import("./pages/Invoices/View/ViewInvoice"));
const EditInvoice = React.lazy(() => import("./pages/Invoices/Edit/EditInvoice"));
const PaymentReceived = React.lazy(() => import("./pages/Payments"));
const Expenses = React.lazy(() => import("./pages/Expenses"));
const CreateExpenses = React.lazy(() => import("./pages/Expenses/Create"));
const ViewExpense = React.lazy(() => import("./pages/Expenses/View/ViewExpense"));
const Bills = React.lazy(() => import("./pages/Bills"));


const RoutesComponent = () => {

  const location = useLocation();
  useWindowScroll(location?.pathname)

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/features/:page" element={<Features />} />
        <Route path="/business/:page" element={<Business />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/developers/api" element={<DeveloperApi />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/password-reset/:id" element={<UserPasswordReset />} />
        <Route path="/password-reset/:id" element={<UserPasswordReset />} />
        <Route path="/dashboard" element={<ProtectedRoute ><Dashboard /></ProtectedRoute>} />
        <Route path="/clients" element={<ProtectedRoute ><Client /></ProtectedRoute>} />
        <Route path="/clients/new" element={<ProtectedRoute ><AddNewClient /></ProtectedRoute>} />
        <Route path="/clients/:id" element={<ProtectedRoute ><UpdateClient /></ProtectedRoute>} />
        <Route path="/inventory" element={<ProtectedRoute ><Inventory /></ProtectedRoute>} />
        <Route path="/inventory/new" element={<ProtectedRoute ><AddNewInventory /></ProtectedRoute>} />
        <Route path="/inventory/:id" element={<ProtectedRoute ><UpdateInventory /></ProtectedRoute>} />
        <Route path="/items-services" element={<ProtectedRoute ><ItemAndService /></ProtectedRoute>} />
        <Route path="/sales/invoices" element={<ProtectedRoute ><Invoices /></ProtectedRoute>} />
        <Route path="/sales/invoices/new" element={<ProtectedRoute ><CreateInvoices /></ProtectedRoute>} />
        <Route path="/sales/invoices/:id" element={<ProtectedRoute ><ViewInvoice /></ProtectedRoute>} />
        <Route path="/sales/invoices/edit/:id" element={<ProtectedRoute ><EditInvoice /></ProtectedRoute>} />
        <Route path="/sales/payment-received" element={<ProtectedRoute ><PaymentReceived /></ProtectedRoute>} />
        <Route path="/purchases/expenses" element={<ProtectedRoute ><Expenses /></ProtectedRoute>} />
        <Route path="/purchases/expenses/new" element={<ProtectedRoute ><CreateExpenses /></ProtectedRoute>} />
        <Route path="/purchases/expenses/:id" element={<ProtectedRoute ><ViewExpense /></ProtectedRoute>} />
        <Route path="/purchases/bills" element={<ProtectedRoute ><Bills /></ProtectedRoute>} />
        <Route path="/purchases/bills/new" element={<ProtectedRoute ><CreateExpenses /></ProtectedRoute>} />
        <Route path="/purchases/bills/:id" element={<ProtectedRoute ><ViewExpense /></ProtectedRoute>} />
        <Route path="/*" element={<ProtectedRoute />} />
      </Routes>
    </Suspense>
  );

};

export default RoutesComponent;
