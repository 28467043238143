import React, { useState } from 'react';
import { Container, Navbar, Offcanvas } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi'
import { BsSearch } from 'react-icons/bs';
import { IoMoonOutline } from "react-icons/io5";
import { GrSun } from "react-icons/gr";
import { PiBellDuotone } from "react-icons/pi";
import Sidebar from '../Sidebar';
import './Navbar.css'

const NavbarComponent = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <div>
        <Navbar>
            <Container fluid>
                <Navbar.Collapse className="">
                    <div className="py-4 align-items-center row w-100"  >
                        <div className="col-4 col-sm-6 col-lg-6">

                            <div className='d-flex align-items-center'>
                                <div className='d-md-none' >
                                    <GiHamburgerMenu onClick={handleShow} />
                                </div>
                                <div className='d-none d-sm-flex align-items-center px-3 py-2 mx-3 w-100' style={{ background: '#F5F4F4', borderRadius: "10px", maxWidth: "80%" }}>
                                    <BsSearch />
                                    <input className='ms-3' placeholder='Search KlaDot…' style={{ paddingRight: '20px', border: 'none', outline: 'none', width: '100%', background: '#F5F4F4', color: "#7D7575", }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-8 col-sm-6 col-lg-6">
                            <div className='d-flex justify-content-end' >
                                <div className='d-flex justify-content-between' style={{ borderRadius: "10px", padding: "10px", background: "rgba(231, 231, 231, 0.49)", width: "100px" }}>
                                    <GrSun size={"23px"} />
                                    <IoMoonOutline size={"23px"} />
                                </div>
                                <div className='ms-2 d-flex justify-content-between' style={{ borderRadius: "10px", padding: "9px 10px", background: "rgba(231, 231, 231, 0.49)", }}>

                                    <PiBellDuotone size={"25px"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div className="row">
            <div className="col-2 col-md-6">
                <Offcanvas closeButton={true} className="canva-width" scroll show={show} onHide={handleClose}>
                    <div style={{ overflowY: 'scroll', height: "100%", background: "#F8F7F7" }} className='sidebar-scroller ' >
                        <Sidebar handleClose={handleClose} />
                    </div>
                </Offcanvas>
            </div>
        </div>
    </div>;
};

export default NavbarComponent;
