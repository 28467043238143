import { ActionTypes } from "../actionTypes";

const initialState = null;

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.User.SET_USER:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
