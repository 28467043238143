import {
  SidebarDashboardActive,
  SidebarDashboardInActive,
  SidebarClientActive,
  SidebarClientInActive,
  SidebarInventoryActive,
  SidebarInventoryInActive,
  SidebarAiAssistantActive,
  SidebarAiAssistantInActive,
  SidebarBankPaymentActive,
  SidebarBankPaymentInActive,
  SidebarContactActive,
  SidebarItemServiceActive,
  SidebarItemServiceInActive,
  SidebarKybCheckActive,
  SidebarKybCheckInActive,
  SidebarMyFinanceActive,
  SidebarMyFinanceInActive,
  SidebarProjectActive,
  SidebarProjectInActive,
  SidebarPurchaseActive,
  SidebarPurchaseInActive,
  SidebarSalesActive,
  SidebarSalesInActive,
  SidebarSimpleHrActive,
  SidebarSimpleHrInActive,
  SidebarContactInActive,
} from "../../assests/Images";

let data = [
  {
    id: 0,
    inActiveImg: SidebarDashboardActive,
    activeImg: SidebarDashboardInActive,
    text: "Dashboard",
    showSubNav: false,
    path: "/dashboard"
  },
  {
    id: 1,
    inActiveImg: SidebarClientActive,
    activeImg: SidebarClientInActive,
    text: "Clients",
    showSubNav: false,
    route: "/clients",
    path: "/clients",
  },
  {
    id: 3,
    inActiveImg: SidebarInventoryActive,
    activeImg: SidebarInventoryInActive,
    text: "Inventory",
    showSubNav: false,
    path: "/inventory",
  },
  {
    id: 4,
    inActiveImg: SidebarItemServiceActive,
    activeImg: SidebarItemServiceInActive,
    text: "Items & Services",
    showSubNav: false,
    path: "/items-services",
  },
  {
    id: 7,
    inActiveImg: SidebarSalesActive,
    activeImg: SidebarSalesInActive,
    text: "Sales",
    showSubNav: true,
    path: "/sales",
    routes: ['/sales/invoices', '/sales/payment-received',],
    subNav: [
      {
        text: 'Invoices',
        path: '/sales/invoices',
      },
      {
        text: 'Payment Received',
        path: '/sales/payment-received',
      }
    ]
  },
  {
    id: 8,
    inActiveImg: SidebarPurchaseActive,
    activeImg: SidebarPurchaseInActive,
    text: "Purchases",
    showSubNav: true,
    path: "/purchases",
    routes: ['/purchases/expenses', '/purchases/bills', '/purchases/payments-made', '/purchases/vendors',],
    subNav: [
      {
        text: 'Expenses',
        path: '/purchases/expenses',
      },
      {
        text: 'Bills',
        path: '/purchases/bills',
      },
      {
        text: 'Payments Made',
        path: '/purchases/payments-made',
      },
      {
        text: 'Vendors',
        path: '/purchases/vendors',
      }
    ]
  },
  {
    id: 8,
    inActiveImg: SidebarMyFinanceActive,
    activeImg: SidebarMyFinanceInActive,
    text: "My Finances",
    showSubNav: true,
    path: "/my-finances",
    routes: ['/my-finances/accounting', '/my-finances/reports',],
    subNav: [
      {
        text: 'Accounting',
        path: '/my-finances/accounting',
      },
      {
        text: 'Reports',
        path: '/my-finances/reports',
      }
    ]
  },
  {
    id: 8,
    inActiveImg: SidebarBankPaymentActive,
    activeImg: SidebarBankPaymentInActive,
    text: "Bank & Payment Link",
    showSubNav: false,
    path: "/Bank-Payment-Link",
  },
  {
    id: 8,
    inActiveImg: SidebarProjectActive,
    activeImg: SidebarProjectInActive,
    text: "Project",
    showSubNav: false,
    path: "/Project",
  },
  {
    id: 8,
    inActiveImg: SidebarContactActive,
    activeImg: SidebarContactInActive,
    text: "Contracts",
    showSubNav: false,
    path: "/Contracts",
  },
  {
    id: 8,
    inActiveImg: SidebarSimpleHrActive,
    activeImg: SidebarSimpleHrInActive,
    text: "Simple HR",
    showSubNav: false,
    path: "/Simple-HR",
  },
  {
    id: 8,
    inActiveImg: SidebarAiAssistantActive,
    activeImg: SidebarAiAssistantInActive,
    text: "AI Assistant",
    showSubNav: false,
    path: "/AI-Assistant",
  },
  {
    id: 8,
    inActiveImg: SidebarKybCheckActive,
    activeImg: SidebarKybCheckInActive,
    text: "KYB Check",
    showSubNav: false,
    path: "/KYB-Check",
  },
];


export default data