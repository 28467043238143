import { ActionTypes } from "../actionTypes";

const initialState = {
    particepent_data: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.Particepent.GET_PARTICEPENT:
            return { ...state, particepent_data: action.payload };
        case ActionTypes.Particepent.DELETE_PARTICEPENT:
            let deleteElement = state?.particepent_data?.filter(x => x._id !== action?.payload?._id);
            return { ...state, particepent_data: deleteElement };
        default:
            return state;
    }
};

export default reducer;
