import { ActionTypes } from "../actionTypes";

const initialState = {
    debitCardData: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.AddOrRecieveFund.Check_Debit_Card:
            return { ...state, debitCardData: action.payload };
        default:
            return state;
    }
};

export default reducer;
