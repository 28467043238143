import NavbarComponent from "../Navbar/Navbar"
import Sidebar from "../Sidebar"
import './ProtectedRoute.css'

const ProtectedRoute = ({ children }) => {
  return <div className="row">
    <div className="d-none p-0 col-md-3 d-md-block sidebar-div sidebar-position-div" >
      <Sidebar />
    </div>
    <div
      className="col-md-9 p-0 right-panel"
    >
      <div className="right-top-panel" id="">
        <NavbarComponent />
      </div>
      <div className="right-bottom-panel">
        {children}
      </div>
    </div>
  </div>
}

export default ProtectedRoute