import { ActionTypes } from "../actionTypes";

const initialState = null;

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.PayForm.ADD_DATA:
			return { ...state, ...action.payload };
		case ActionTypes.PayForm.RESET_FORM:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
