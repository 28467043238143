import React from "react";
import "./Sidebar.css";
import { Avatar } from "@mui/material";
import { FaAngleDown } from "react-icons/fa6";
import { TbLogout } from "react-icons/tb";
import SubMenu from "./SubMenu";
import sidebarRoutes from "./sidebarRoutes";
import { useNavigate } from "react-router-dom";
import { useHeightSize } from "../../utils/hooks";

const Sidebar = ({ handleClose }) => {
  const height = useHeightSize()
  const navigation = useNavigate()

  const handleLogout = () => {
    navigation('/login')
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-links-container mt-0 pt-5">
        <div className="sidebar-links px-3 align-items-center">
          <Avatar sx={{ bgcolor: '#D9D9D9', color: '#0B436D', fontSize: "14px", fontWeight: "600" }}>CA</Avatar>
          <div className="mx-3 d-flex flex-column">
            <div className="user-name-first">Benedict,</div>
            <div className="user-name-second mt-3 pt-1">Creative Agency</div>
          </div>
          <FaAngleDown className="me-2" color="#fff" />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div style={{ background: "rgba(186, 187, 189, 0.17)", height: "0.5px", width: "80%" }}></div>
      </div>
      <div className="sidebar-link-main-container " style={{ height: `${height - 337}px`, overflowY: 'auto' }}>
        {sidebarRoutes?.map((item) => (
          <SubMenu handleClose={handleClose} item={item} />
        ))}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div style={{ background: "rgba(186, 187, 189, 0.17)", height: "0.5px", width: "80%" }}></div>
      </div>
      <div className="sidebar-logout-container" >
        <div className="sidebar-links ps-3 align-items-baseline">
          <div className="sidebar-links-img" style={{ flex: 0.5 }}>
            <TbLogout size={"20px"} color="#fff" />
          </div>
          <div
            onClick={handleLogout}
            className="sidebar-links-text"
            style={{ flex: 4, cursor: "pointer" }}
          >
            <p className="sidebar-links-text-p" >Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
