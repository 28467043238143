import { useLayoutEffect, useState } from "react"

// Scroll to top if step changes
export const useWindowScroll = (step) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);
}

// Get the updated height of the screen
export const useHeightSize = () => {
  const { innerHeight } = window
  const [height, setHeight] = useState(0)
  useLayoutEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', updateHeight)
    updateHeight()
    return () => window.removeEventListener('resize', updateHeight)
  }, [innerHeight])
  return height
}